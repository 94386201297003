<template>
    <div class="row">
        <div class="col-12 mb-3">
            <div class="card pt-4 mb-xl-9">
                <div class="card-header border-0">
                    <div class="card-title">
                        <h2 class="fw-bolder">{{ $t("pages.results.title") }}</h2>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="fw-bolder fs-2">
                        <div class="fs-7 fw-normal text-muted">
                            {{ $t("pages.results.desc") }}
                        </div>
                    </div>
                    <div class="p-5 mt-5 mb-5 bg-primary bg-opacity-25 rounded">
                        <div class="row">
                            <div class="col form-group">
                                <select v-model="selectedCampaignId" class="form-control form-control-solid">
                                    <option v-for="campaign in campaigns" v-bind:key="campaign.id" :name="campaign.label" :value="campaign.id">{{ campaign.label }}</option>
                                </select>
                            </div>
                            <div class="col">
                                <button type="submit" class="btn btn-primary btn-light" @click="reloadData()">
                                    {{ $t("general.search") }}
                                </button>
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Row-->
                    </div>
                </div>
            </div>
        </div>
        <template v-if="!loading">
            <div class="col-4 mb-4">
                <div class="card bg-warning bg-opacity-25 h-100">
                    <div class="card-header flex-nowrap border-0 pt-9">
                        <div class="card-title m-0">
                            <i class="fas fa-chart-line fs-3 me-3"></i>
                            <a class="fs-4 fw-bold text-hover-primary text-gray-600 m-0" href="#">{{ $t("pages.results.groupreport") }}</a>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column pb-8">
                        <div class="d-flex align-items-center fw-bold">
                            <a v-if="this.groupReportLink" class="btn btn-warning btn-sm btn-light btn-active-light-primary" target="_blank" @click="this.downloadGroupReport()">{{
                                $t("pages.results.accessResults")
                            }}</a>
                            <a v-else class="btn bg-black text-white btn-sm btn-active-light-primary disabled" target="_blank"> {{ $t("general.noavailable") }} </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 mb-4">
                <div class="card bg-warning bg-opacity-25 h-100">
                    <div class="card-header flex-nowrap border-0 pt-9">
                        <div class="card-title m-0">
                            <i class="fas fa-chart-line fs-3 me-3"></i>
                            <a class="fs-4 fw-bold text-hover-primary text-gray-600 m-0" href="#">{{ $t("pages.results.downloadbyperimeter") }}</a>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column pb-8">
                        <div v-if="!loadingZip" class="d-flex align-items-center fw-bold">
                            <a class="btn btn-warning btn-sm btn-light btn-active-light-primary" target="_blank" @click="this.getAllReports()">{{
                                $t("pages.results.accessResults")
                            }}</a>
                        </div>
                        <div v-else class="row-auto flex-center">
                            <div class="progress">
                                <span
                                    class="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    :style="{ width: progressBarWidth + '%' }"
                                    :aria-valuenow="progressBarWidth"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></span>
                            </div>
                            <div class="row-auto flex-center mt-4">
                                {{ $t("pages.results.loadingZip") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 mb-4">
                <div class="card bg-warning bg-opacity-25 h-100">
                    <div class="card-header flex-nowrap border-0 pt-9">
                        <div class="card-title m-0">
                            <i class="fas fa-chart-line fs-3 me-3"></i>
                            <a class="fs-4 fw-bold text-hover-primary text-gray-600 m-0" href="#">Rapports spécifiques</a>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column pb-8">
                        <div class="d-flex align-items-center fw-bold">
                            <router-link to="/results/specific/">
                                <a class="btn btn-warning btn-sm btn-light btn-active-light-primary" target="_blank">{{ $t("pages.results.accessResults") }}</a>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 mb-4" v-if="this.$store.getters.currentUser.user.userprofile.softconcept">
                <div class="card bg-warning bg-opacity-25 h-100">
                    <div class="card-header flex-nowrap border-0 pt-9">
                        <div class="card-title m-0">
                            <i class="fas fa-chart-line fs-3 me-3"></i>
                            <a class="fs-4 fw-bold text-hover-primary text-gray-600 m-0" href="#">{{ $t("pages.results.customizewebreporting") }}</a>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column pb-8">
                        <div class="d-flex align-items-center fw-bold">
                            <a
                                :href="this.$store.getters.currentUser.user.userprofile.softconcept"
                                class="btn btn-warning btn-sm btn-light btn-active-light-primary"
                                target="_blank"
                                >{{ $t("pages.results.accessResults") }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-4">
                <div class="card h-100">
                    <div class="card-header flex-nowrap border-0 pt-9">
                        <div class="card-title m-0">
                            <i class="fas fa-chart-line fs-3 me-3"></i>
                            <a class="fs-4 fw-bold text-hover-primary text-gray-600 m-0" href="#">{{ $t("pages.results.entityreport") }}</a>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column pb-8">
                        <el-tree
                            :data="entities"
                            :default-expanded-keys="defaultExpandedKeys"
                            node-key="id"
                            ref="tree"
                            highlight-current
                            :props="defaultProps"
                            @node-expand="handleNodeClick"
                            @node-collapse="handleNodeClick"
                        >
                            <template #default="{ node, data }">
                                <span class="custom-tree-node">
                                    <span>{{ node.label }} </span>
                                    <span>
                                        <template v-if="data.externalcompanyresults.length > 0">
                                            <a :href="data.externalcompanyresults[0].link" target="_blank" class="primary-color"> Accéder aux Résultats </a>
                                        </template>
                                        <template v-if="data.resultfile">
                                            <a
                                                v-for="fileres in data.resultfile"
                                                :key="fileres.id"
                                                @click="downloadReport(fileres)"
                                                target="_blank"
                                                class="primary-color"
                                                style="z-index: 100"
                                                :title="fileres.name"
                                            >
                                                <span v-if="fileres.extension === 'pdf'">
                                                    <i class="fas fa-solid fa-file-pdf me-3 fs-5"></i>
                                                </span>
                                                <span v-else-if="fileres.extension === 'xlsx'">
                                                    <i class="fas fa-solid fa-file-excel me-3 fs-5"></i>
                                                </span>
                                                <span v-else-if="fileres.extension === 'csv'">
                                                    <i class="fas fa-solid fa-file-csv me-3 fs-5"></i>
                                                </span>
                                                <span v-else-if="fileres.extension === 'txt'">
                                                    <i class="fas fa-solid fa-file-alt me-3 fs-5"></i>
                                                </span>
                                                <span v-else-if="fileres.extension === 'pptx'"><i class="fas fa-solid fa-file-powerpoint me-3 fs-5"></i></span>
                                                <span v-else-if="fileres.extension === 'docx'"><i class="fas fa-sharp fa-solid fa-file-word me-3 fs-5"></i></span>
                                                <span v-else-if="fileres.extension === 'doc'"><i class="fas fa-sharp fa-solid fa-file-word me-3 fs-5"></i></span>
                                                <span v-else-if="fileres.extension === 'jpeg' || fileres.extension === 'jpg'"
                                                    ><i class="fas fa-sharp fa-solid fa-file-image me-3 fs-5"></i
                                                ></span>
                                                <span v-else>Télécharger {{ fileres.extension }}</span>
                                            </a>
                                        </template>
                                        <template v-else> Résultats non disponible </template>
                                    </span>
                                </span>
                            </template>
                        </el-tree>
                    </div>
                </div>
            </div>
        </template>

        <div v-else>
            <div class="d-flex row-auto flex-center w-100 h-200px">
                <span class="spinner-border text-primary" role="status"></span>
            </div>
        </div>
    </div>
</template>
<style>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
</style>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import apiSettings from "@/core/services/Settings";
import apiEntity from "@/core/services/Entities";
import apiResult from "@/core/services/Result";
import campaignApi from "@/core/services/Campaign";
import resultApi from "@/core/services/Result";
import ApiService from "@/core/services/ApiService";
import { GET_ALL_REPORTS_URL } from "@/core/services/Result";

export default defineComponent({
    name: "results",
    components: {},
    data() {
        return {
            defaultExpandedKeys: [] as number[],
            groupReport: "",
            groupReportLink: "",
            entities: [],
            defaultProps: {
                children: "companies",
                label: "name",
            },
            selectedCampaignId: 0,
            loading: false,
            loadingZip: false,
            progressBarWidth: 0,
            campaigns: [] as any,
        };
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.results.title"), []);
        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.results.title"), []);
        },
    },
    methods: {
        reloadData() {
            this.fetchEntities();
            this.fetchGroupReport();
        },
        handleNodeClick(data) {
            if (this.defaultExpandedKeys.indexOf(data.id) !== -1) {
                this.defaultExpandedKeys.splice(this.defaultExpandedKeys.indexOf(data.id), 1);
            } else {
                if (data && data.id) {
                    // Check if the node already has children loaded
                    this.fetchEntities(data.id);
                    this.defaultExpandedKeys.push(data.id);
                }
            }
        },
        updateTree(company_id, children) {
            const findAndUpdateNode = (nodes) => {
                return nodes.map((node) => {
                    if (node.id === company_id) {
                        // Return a new object with updated companies
                        return { ...node, companies: children };
                    } else if (node.companies && node.companies.length) {
                        // Recursively update child nodes
                        return { ...node, companies: findAndUpdateNode(node.companies) };
                    } else {
                        // Return the node as is if no updates are needed
                        return node;
                    }
                });
            };

            // Update the entities with the new structure
            this.entities = findAndUpdateNode(this.entities);
        },
        fetchEntities(company_id = null) {
            let params = { campaign: 0, company_id: null };
            if (this.selectedCampaignId) {
                params.campaign = this.selectedCampaignId;
            }
            if (company_id) {
                params.company_id = company_id;
            }
            apiEntity.getHierarchicalEntities(params).then((response) => {
                if (company_id) {
                    this.updateTree(company_id, response.data["hierarchical_companies"]);
                } else {
                    this.entities = response.data["hierarchical_companies"];
                }
            });
        },
        fetchCampaigns() {
            campaignApi.getCampaigns().then((response) => {
                this.campaigns = response.data["campaigns"];
                this.selectedCampaignId = this.campaigns[0].id;
                this.fetchEntities();
                this.fetchGroupReport();
            });
        },
        getAllReports() {
            this.updateProgressBar(0);
            this.loadingZip = true;
            this.axios
                .post(ApiService.returnUrl() + GET_ALL_REPORTS_URL, { campaign: this.selectedCampaignId }, { responseType: "blob" })
                .then((response) => {
                    const blob = new Blob([response.data], { type: "application/zip" });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "reports.zip";
                    link.click();
                    window.URL.revokeObjectURL(url);
                    setTimeout(() => {
                        this.updateProgressBar(100);
                        setTimeout(() => {
                            this.loadingZip = false;
                        }, 1500);
                    }, 500);
                })
                .catch((error) => {
                    console.log("error", error);
                });
        },
        downloadGroupReport() {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = this.groupReportLink;
            a.download = "rapport-groupe.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        downloadReport(fileres) {
            resultApi.getReport(fileres.id).then((response) => {
                let report = response.data.report;
                let type = report.extension;
                let original_type = type;
                if (type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    type = "xlsx";
                }
                let linkSource = report.file;
                const downloadLink = document.createElement("a");
                const fileName = report.name + "_" + report.id + "." + type;
                let dataPart = "";
                if (type === "pdf") {
                    dataPart = "data:application/pdf;base64,";
                } else if (type === "xlsx") {
                    dataPart = "data:vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
                } else if (type === "xls") {
                    dataPart = "data:application/vnd.ms-excel;base64,";
                } else if (type === "ppt") {
                    dataPart = "data:application/vnd.ms-powerpoint;base64,";
                } else if (type === "pptx") {
                    dataPart = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,";
                } else if (type === "docx") {
                    dataPart = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
                } else if (type === "doc") {
                    dataPart = "data:application/msword;base64,";
                } else if (type === "txt") {
                    dataPart = "data:text/plain;base64,";
                } else if (type === "csv") {
                    dataPart = "data:text/csv;base64,";
                } else if (type === "jpeg" || type === "jpg") {
                    dataPart = "data:image/jpeg;base64,";
                }
                if (linkSource.startsWith("b'")) {
                    linkSource = linkSource.substring(2);
                }
                if (linkSource.endsWith("'")) {
                    linkSource = linkSource.slice(0, -1);
                }
                let total = dataPart + linkSource;
                downloadLink.href = total;
                downloadLink.download = fileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });
        },
        fetchGroupReport() {
            apiSettings.getGroupReport({ year: this.selectedCampaignId }).then((response) => {
                if ("result" in response.data && "file" in response.data["result"]) {
                    this.groupReport = response.data["result"]["file"];
                    if (this.groupReport && this.groupReport !== "") {
                        const base64result = this.groupReport.split(",")[1];
                        const blob = this.b64toBlob(base64result, this.groupReport.split(",")[0]);
                        this.groupReportLink = URL.createObjectURL(blob);
                    } else {
                        this.groupReportLink = "";
                    }
                } else {
                    this.groupReportLink = "";
                }
            });
        },
        b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
            const byteCharacters = atob(b64Data);
            const byteArrays = [] as Array<Uint8Array>;

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        },
        startProgressBarTimer() {
            setInterval(() => {
                if (this.progressBarWidth < 90) {
                    this.progressBarWidth += 10;
                }
            }, 1000);
        },
        updateProgressBar(progress) {
            this.progressBarWidth = progress;
        },
    },
    mounted() {
        this.startProgressBarTimer();
        setTimeout(() => {
            this.updateProgressBar(90);
        }, 9000);
    },
    created() {
        this.fetchCampaigns();
    },
});
</script>
